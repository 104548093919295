export function getWeglotLanguage() {
  const url = new URL(window.location.href);
  const urlParts = url.pathname.split('/');
  const lang = urlParts[1];

  // Supported languages
  const supportedLanguages = ['fr', 'en', 'it', 'de'];

  // If the first part of the URL is valid, return it; otherwise default to 'de'
  return supportedLanguages.includes(lang) ? lang : 'de';
}

export function fixRevSliderLinks() {
  const currentLang = getWeglotLanguage();
  // If in default language 'de', we skip prefixing
  if (currentLang === 'de') return;
  
  // Query anchors inside .revslider-lnk or a relevant container
  const links = document.querySelectorAll('sr7-module a');
  links.forEach(anchor => {
    const anchorUrl = new URL(anchor.getAttribute('href'), window.location.origin);
    // Only rewrite if same domain
    if (anchorUrl.host === window.location.host) {
      const pathParts = anchorUrl.pathname.split('/').filter(Boolean);
      const firstSegment = pathParts[0] || '';

      const supportedLanguages = ['fr','en','it','de'];
      if (!supportedLanguages.includes(firstSegment)) {
        // Insert the current language into the path if not already present
        pathParts.unshift(currentLang);
        anchorUrl.pathname = '/' + pathParts.join('/')+ '/';
        anchor.setAttribute('href', anchorUrl.toString());
      }
    }
  });
}

// Setup a MutationObserver only on .sr7-module
const observer = new MutationObserver(() => {
  fixRevSliderLinks();
});

const sr7Module = document.querySelector('sr7-module');
if (sr7Module) {
  observer.observe(sr7Module, {
    childList: true,
    subtree: true
  });
}