import $ from '../jquery-shim.js';

export function loadComments() {

   
    const commentSection = document.querySelector("#comments");

    if (commentSection) {
        const postId = commentSection.dataset.postId; // Add `data-post-id` to the comment section in PHP

        // Function to load the comment form via AJAX
        const loadCommentForm = async () => {
            try {
                const response = await fetch(shisha_values.ajaxurl, {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: new URLSearchParams({
                        action: "load_comment_form",
                        post_id: postId,
                    }),
                });

                const result = await response.json();

                if (result.success && result.data.comment_form) {
                    const formContainer = document.querySelector(".comment-form-container");
                    formContainer.innerHTML = result.data.comment_form;
                } else {
                    console.error("Failed to load comment form:", result.data.message);
                }
            } catch (error) {
                console.error("Error loading comment form:", error);
            }
        };

        // Load the comment form dynamically
        loadCommentForm();
    }

    const match = document.cookie.match(/comment_pending=([^;]+)/);
    if (match) {
        $("#comments").prepend(
            "<div class='tw-relative tw-bg-dh_lightblue tw-rounded-xl tw-px-6 tw-py-6 tw-my-12 tw-text-dh_blue'>Dein Kommentar wurde gesendet und wird derzeit geprüft.</div>"
        );
        document.cookie = 'comment_pending=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
}
