import $ from '../jquery-shim.js';

export function initializeImageNavigation() {
  const $mainPicture = $('#dh-main-product-image');
  if (!$mainPicture.length) return;
  
  let currentIndex = 0;
  const $arrowLeft       = $('.arrow-left');
  const $arrowRight      = $('.arrow-right');
  const $thumbButtons    = $('.dh-thumb');
  const $thumbContainer  = $('.dh-thumbs');
  const $thumbLeftArrow  = $('.thumb-arrow-left');
  const $thumbRightArrow = $('.thumb-arrow-right');

  // Build high-res images collection
  const images = $thumbButtons.map((_, thumb) => ({
    large: $(thumb).data('large'),
    alt:   $(thumb).find('img').attr('alt'),
  })).get();

  // Update main image and active thumbnail state
  function updateMainImage(index) {
    if (index < 0) index = images.length - 1;
    if (index >= images.length) index = 0;
    currentIndex = index;
    
    // Update picture element's source and img
    const $source = $mainPicture.find('source');
    const $img = $mainPicture.find('img');
    
    if ($source.length) {
      $source.attr('srcset', images[currentIndex].large + '.webp');
    }
    
    $img.attr({
      src: images[currentIndex].large,
      alt: images[currentIndex].alt
    });

    // Update thumbnail active state with opacity changes
    $thumbButtons
      .removeClass('tw-opacity-100')
      .addClass('tw-opacity-60');
    $thumbButtons.eq(currentIndex)
      .removeClass(' tw-opacity-60')
      .addClass('tw-opacity-100');

    // Scroll to active thumbnail
    const thumb = $thumbButtons.eq(currentIndex)[0];
    if (thumb) {
      const container = $thumbContainer[0];
      const thumbLeft = thumb.offsetLeft;
      const containerWidth = container.clientWidth;
      const scrollTo = thumbLeft - (containerWidth - thumb.offsetWidth) / 2;
      container.scrollTo({ left: scrollTo, behavior: 'smooth' });
    }
  }

  // Main image navigation
  $arrowLeft.on('click', () => updateMainImage(currentIndex - 1));
  $arrowRight.on('click', () => updateMainImage(currentIndex + 1));
  
  // Thumbnail clicks
  $thumbButtons.each((i, btn) => {
    $(btn).on('click', () => updateMainImage(i));
  });

  // Thumbnail arrows update: add or remove "tw-hidden" class
  function updateThumbArrows() {
    const scrollLeft = $thumbContainer.scrollLeft();
    const maxScroll  = $thumbContainer[0].scrollWidth - $thumbContainer[0].clientWidth;
    if (scrollLeft <= 0) {
      $thumbLeftArrow.addClass('tw-hidden');
    } else {
      $thumbLeftArrow.removeClass('tw-hidden');
    }
    if (scrollLeft >= maxScroll) {
      $thumbRightArrow.addClass('tw-hidden');
    } else {
      $thumbRightArrow.removeClass('tw-hidden');
    }
  }

  $thumbLeftArrow.on('click', () => {
    const scrollAmount = $thumbContainer[0].clientWidth;
    $thumbContainer.animate({ scrollLeft: `-=${scrollAmount}` }, 300, updateThumbArrows);
  });
  $thumbRightArrow.on('click', () => {
    const scrollAmount = $thumbContainer[0].clientWidth;
    $thumbContainer.animate({ scrollLeft: `+=${scrollAmount}` }, 300, updateThumbArrows);
  });
  $thumbContainer.on('scroll', updateThumbArrows);
  $(window).on('resize', updateThumbArrows);
  updateThumbArrows();
  updateMainImage(0);

  // Lightbox functionality
  const $lightbox      = $('#dh-lightbox');
  const $lightboxImage = $lightbox.find('img');
  const $lightboxClose = $lightbox.find('.lightbox-close');

  function openLightbox() {
    $lightboxImage.attr({
      src: images[currentIndex].large,
      alt: images[currentIndex].alt
    });
    $lightbox.removeClass('tw-hidden');
  }

  function closeLightbox() {
    $lightbox.addClass('tw-hidden');
  }

  // Open lightbox when clicking on main image
  $mainPicture.on('click', openLightbox);

  // Close lightbox when clicking the close button or outside the content
  $lightboxClose.on('click', closeLightbox);
  $lightbox.on('click', function(e) {
    if ($(e.target).is('#dh-lightbox')) {
      closeLightbox();
    }
  });
}