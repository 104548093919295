import $ from '../jquery-shim.js';

export function generateTopbarSlider() {
  var $slider = $('.usp-topbar-slider');
  var $slides = $slider.children();
  var current = 0;

  function showSlide(index) {
    $slides.hide().eq(index).slideDown();
  }

  setInterval(function() {
    current = (current + 1) % $slides.length;
    showSlide(current);
  }, 2500);
}

