import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faArrowTurnDownRight, faCheck, faChevronRight, faChevronLeft, faChevronDown, faChevronUp, faCartPlus, faCircleCheck, faCompass, faList } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

export function loadFontAwesome() {
    library.add(faSpinnerThird, faArrowTurnDownRight, faCheck, faChevronRight, faChevronLeft, faChevronDown, faChevronUp, faCartPlus, faCircleCheck,  faCompass, faList);
    dom.watch();
}


