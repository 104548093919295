// Imported icons that can be used in ::before and ::after
import '../shared/pseudo-font-awesome/css/all.min.css'

// CSS imports remain the same since they're already correct
import '../shared/css/app.css';
import '../shared/css/base.css';
import '../css/theme.css';
import '../shared/css/shared.css';

// Update JS imports to match new structure
import {
  loadFontAwesome
} from '../shared/js/global/fa-loader.js';
import {
  initializeReadMoreLink
} from '../shared/js/category/read-more-link.js';
import {
  generateScrollbars
} from '../shared/js/global/custom-scrollbar.js';
import {
  initializeElfbarCalculator
} from '../shared/js/global/elfbar-calculator.js';
import {
  initializeMobileMenu
} from '../shared/js/global/mobile-menu.js';
import {
  initializeSearchSuggestions
} from '../shared/js/global/search-suggestions.js';
import {
  triggerMiniCart
} from '../shared/js/global/trigger-minicart.js';
import {
  addWidgetClickHandler
} from '../shared/js/global/trusted-shops.js';
import {
  generateTopbarSlider
} from '../shared/js/global/topbar-slider.js';
import $ from '../shared/js/jquery-shim.js';
import {
  cf_checkStockMultipleProducts,
  cf_checkStockSingleProduct,
} from '../shared/js/product/cloudflare-stock.js';
import {
  updateDeliveryInfo
} from '../shared/js/product/delivery-info.js';
import {
  initializeImageNavigation
} from '../shared/js/product/image-navigation.js';
import {
  initializeVariationDropdown
} from '../shared/js/product/variation-dropdown.js';
import {
  ajaxAddToCartHandler
} from '../shared/js/product-category/add-to-cart-handler.js';
import {
  listenToStickyFilter
} from '../shared/js/product-category/sticky-filter.js';
import {
  toggleCategoryTabs
} from '../shared/js/category/toggle-tabs.js';
import {
  toggleAccordion
} from '../shared/js/global/accordion.js';
import {
  toggleCategoryBlogSidebar
} from '../shared/js/category/blog-sidebar.js';
import {
  generateStickyTableOfContent
} from '../shared/js/blog/table-of-content.js';
import {
  loadComments
} from '../shared/js/blog/load-comments.js';
import {
  yithFilterClickHandler
} from '../shared/js/product-category/yith-filter-handler.js';
import { fixRevSliderLinks } from '../shared/js/global/weglot-language.js';

$(function () {
  loadFontAwesome();
  initializeMobileMenu();
  generateScrollbars();
  initializeVariationDropdown();
  initializeImageNavigation();
  addWidgetClickHandler();
  updateDeliveryInfo();
  generateTopbarSlider();
  fixRevSliderLinks();
  listenToStickyFilter();
  ajaxAddToCartHandler();
  toggleCategoryTabs();
  toggleAccordion();
  toggleCategoryBlogSidebar();

  // Detect if on a single product page
  if ($('body').hasClass('single-product')) {
    cf_checkStockSingleProduct();
  } else if ($('body').hasClass('tax-product_cat')) {
    cf_checkStockMultipleProducts();
  }

  initializeSearchSuggestions();
  initializeElfbarCalculator();
  initializeReadMoreLink();
  triggerMiniCart();
  generateStickyTableOfContent();
  loadComments();
  yithFilterClickHandler();
});

