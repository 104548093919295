import $ from '../jquery-shim.js';

const CLOUD_FLARE_WORKER_URL = shisha_values.cloudflareServiceUrl + '/get-stock';
const STORE_ID = shisha_values.storeId || 'shisha';

export async function cf_checkStockSingleProduct() {
  const $quantityInput = $('button.single_add_to_cart_button')
    .closest('form')
    .find('.qty');
  const productId = $('button.single_add_to_cart_button').val();
  const cachedStock = parseInt($quantityInput.attr('max'), 10);

  if (isNaN(cachedStock)) {
    return;
  }

  if (!productId) {
    return;
  }

  try {
    const response = await fetch(
      `${CLOUD_FLARE_WORKER_URL}?product_id=${productId}`,
      {
        headers: {
          'x-store-id': STORE_ID
        }
      }
    );
    const stockData = await response.json();
    let actualStock = stockData.stock_quantity;

    if (actualStock > 50) {
      actualStock = 50;
    }

    if (actualStock !== undefined) {
      $quantityInput.attr('max', actualStock);
    } 
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function cf_checkStockMultipleProducts() {
  let currentUrl = new URL(window.location.href);
  if (currentUrl.searchParams.has('yith_wcan')) {
    return;
  }

  let productsToCheck = {}; // Map of productId to product info

  $('.product-small.product.type-product').each(function () {
    const $product = $(this);
    const productId = $product.find('.add_to_cart_button').data('product_id');
    const $quantityInput = $product.find('.qty');
    const maxDisplayedStock = parseInt($quantityInput.attr('max'), 10);
    const isInitiallyOutOfStock = $product.hasClass('out-of-stock');

    if (isNaN(maxDisplayedStock) || maxDisplayedStock < 100) {
      productsToCheck[productId] = {
        isInitiallyOutOfStock: isInitiallyOutOfStock,
        $product: $product,
      };
    }
  });

  const productIdsToCheck = Object.keys(productsToCheck);

  if (productIdsToCheck.length > 0) {
    try {
      const response = await fetch(
        `${CLOUD_FLARE_WORKER_URL}?product_ids=${productIdsToCheck.join(',')}`,
        {
          headers: {
            'x-store-id': STORE_ID
          }
        }
      );
      const stockData = await response.json();

      productIdsToCheck.forEach((productId) => {
        const data = stockData[productId];
        const stockQty = data.stock_quantity;

        const productInfo = productsToCheck[productId];
        const $product = productInfo.$product;
        const isInitiallyOutOfStock = productInfo.isInitiallyOutOfStock;

        // Select the element with the data-productid attribute directly
        const $productBox = $(
          `.product-small.box[data-productid='${productId}']`
        );

        // Current stock status from Cloudflare
        const isNowOutOfStock = !(stockQty > 0);

        // Compare initial and current stock status
        if (isInitiallyOutOfStock === isNowOutOfStock) {
          // Stock status matches, no need to move
        } else {
          // Stock status differs, need to move the product
          if (isInitiallyOutOfStock && !isNowOutOfStock) {
            // Initially out of stock, now in stock
            // Move product after last in-stock product
            moveProductAfterLastInStock($product);
          } else if (!isInitiallyOutOfStock && isNowOutOfStock) {
            // Initially in stock, now out of stock
            // Move product to bottom
            moveProductToBottom($product);
          }
        }

        // Update the UI accordingly
        const $quantityInput = $product.find('.qty');
        const $outOfStockLabel = $product.find('.out-of-stock-label');
        const $boxImage = $product.find('.box-image');

        // Retrieve stock status message and color from $productBox
        const stockStatusMessage = $productBox.data('stockstatus');
        const stockStatusColor = $productBox.data('stockstatuscolor');

        if (!isNowOutOfStock) {
          // Now in stock
          $product.removeClass('out-of-stock');
          $quantityInput.attr('max', Math.min(stockQty, 50));
          $outOfStockLabel.hide();
          $boxImage.append($outOfStockLabel);
          $product.find('.add_to_cart_button, .ux-quantity').show();
        } else {
          // Now out of stock
          $product.addClass('out-of-stock');
          // Apply custom stock status message
          if ($outOfStockLabel.length === 0) {
            $boxImage.append(
              `<div class="out-of-stock-label tw-flex tw-justify-center tw-items-center tw-gap-3.5 tw-opacity-100 ">
                  <svg class="tw-h-4 tw-w-4 tw-overflow-visible" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50%" cy="50%" r="50%" fill="${stockStatusColor}" />
                  </svg> 
                  <span>${stockStatusMessage}</span>
               </div>`
            );
          } else {
            $outOfStockLabel.show();
          }
          $product.find('.add_to_cart_button, .ux-quantity').hide();
        }
      });
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  }
}

// Move product after the last in-stock product
function moveProductAfterLastInStock($product) {
  const $parent = $product.parent();
  // Find all in-stock products
  const $inStockProducts = $parent
    .children('.product-small.product.type-product')
    .filter(function () {
      return !$(this).hasClass('out-of-stock');
    });

  if ($inStockProducts.length > 0) {
    const $lastInStockProduct = $inStockProducts.last();
    $product.insertAfter($lastInStockProduct);
  } else {
    // No in-stock products, prepend to parent
    $parent.prepend($product);
  }
}

// Move out-of-stock products to the bottom of the list
function moveProductToBottom($product) {
  const $parent = $product.parent();
  $parent.append($product); // Moves product to the bottom of the list
}