import $ from '../jquery-shim.js';

export function yithFilterClickHandler() {
    // For normal clickable items
    $(document).on(
        'click',
        '.yith-wcan-filters a, .yith-wcan-filters button, .yith-wcan-filters li.filter-item',
        function () {
            showSpinnerOverlay();
        }
    );

    // For hidden <select> filters changing value
    $(document).on('change', '.yith-wcan-filters select.filter-dropdown.filter-items', function () {
        showSpinnerOverlay();
    });

    // Remove spinner once YITH finishes loading
    $(document).on('yith-wcan-ajax-filtered', function () {
        $('.my-spinner-overlay').remove();

        // Example: close popup on mobile
        if ($('button.mfp-close').length) {
            $('button.mfp-close').click();
        }
    });
}

// Simple helper function to show spinner
function showSpinnerOverlay() {
    if ($('.my-spinner-overlay').length) return;
    $('body').append(`
    <div class="tw-fixed tw-h-full tw-z-[100999999900000] tw-w-full tw-bg-white/40 tw-top-0 tw-bottom-0 my-spinner-overlay tw-flex tw-items-center tw-justify-center">
      <i class="fa-duotone fa-2xl fa-solid fa-spinner-third fa-spin" style="--fa-primary-color: #eee; --fa-secondary-color: #ff0000;"></i>
    </div>
  `);
}