import $ from '../jquery-shim.js';

export function ajaxAddToCartHandler() {
  $('.woocommerce .products').on('click', '.quantity input', function () {
    return false;
  });

  // Enforce max on input changes
  $('.woocommerce .products').on('change input', '.quantity .qty', function () {
    const $this = $(this);
    const maxAttr = parseInt($this.attr('max'), 10) || 9999; // fallback if no max
    let quantity = parseInt($this.val(), 10) || 1;

    // Clamp quantity to max
    if (quantity > maxAttr) {
      quantity = maxAttr;
      $this.val(quantity);
    }

    // Update the add_to_cart_button
    let add_to_cart_button = $this.parents('.product').find('.add_to_cart_button');
    add_to_cart_button.attr('data-quantity', quantity);
    add_to_cart_button.attr(
      'href',
      '?add-to-cart=' + add_to_cart_button.data('product_id') + '&quantity=' + quantity
    );
  });

  $('.product-small.product').on('change input', '.quantity .qty', function () {
    const $this = $(this);
    const maxAttr = parseInt($this.attr('max'), 10) || 9999;
    let quantity = parseInt($this.val(), 10) || 1;

    if (quantity > maxAttr) {
      quantity = maxAttr;
      $this.val(quantity);
    }

    let add_to_cart_button = $this.parent().parent().find('.add_to_cart_button');
    add_to_cart_button.attr('data-quantity', quantity);
    add_to_cart_button.attr(
      'href',
      '?add-to-cart=' + add_to_cart_button.data('product_id') + '&quantity=' + quantity
    );
  });

  // Trigger on Enter press
  $('.woocommerce .products').on('keypress', '.quantity .qty', function (e) {
    if ((e.which || e.keyCode) === 13) {
      $(this).parents('.product').find('.add_to_cart_button').trigger('click');
    }
  });
}