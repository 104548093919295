import $ from "../jquery-shim.js";

export function generateStickyTableOfContent() {
  const tocToggle = $("#toc-toggle");
  const staticTocContent = $("#toc-content");
  const contentContainer = $("#dh-blog-content");
  const mobileTocWrapper = $("div#content.blog-wrapper"); // Use a specific container for the TOC

  if (!contentContainer.length) {
    return;
  }


  var tocStaticPosition = tocToggle.position();
  const headerHeight = 70; // Adjust this if your header height is different
  const tocToggleHeight = 60;
  let combinedOffset = headerHeight + tocToggleHeight;
  let headerOffset = headerHeight; // Initialize with desktop value

  let isAnimating = false;
  let isTocOpen = false;

  // Function to check if the screen is desktop size
  function isDesktop() {
    return window.matchMedia("(min-width: 1220px)").matches; // Adjust breakpoint as needed
  }

  // Function to update `headerOffset` based on screen size
  function updateHeaderOffset() {
    headerOffset = isDesktop() ? headerHeight : headerHeight + tocToggleHeight;
  }

  // Initial calculation
  updateHeaderOffset();

  // Recalculate `headerOffset` on window resize
  $(window).on("resize", updateHeaderOffset);

  // Mobile TOC Toggle
  tocToggle.on("click", function () {
    if (isAnimating) return;
    if (!$(this).hasClass("tw-sticky")) {
      return;
    }
    
    isAnimating = true;
    isTocOpen = !isTocOpen;
    const arrowIcon = $(this).find(".fa-chevron-down");


    if (isTocOpen) {
      // Generate a secondary TOC (clone content for mobile view)
      const mobileTocContent = staticTocContent.clone().attr("id", "mobile-toc-content");
      mobileTocWrapper.append(mobileTocContent); // Append to wrapper instead of body
      mobileTocContent.hide();
      highlightActiveSection();
      mobileTocContent.addClass(
        "tw-fixed tw-top-[130px] tw-left-0 tw-right-0 tw-bg-white tw-z-[10] tw-5 tw-shadow-lg"
      );
      mobileTocContent.slideDown(200);
      arrowIcon.addClass("tw-rotate-180");
    } else {
      // Close TOC
      arrowIcon.removeClass("tw-rotate-180");
      closeMobileToc($("#mobile-toc-content"));
    }

    isAnimating = false;
  });

  function closeMobileToc(mobileTocContent) {
    mobileTocContent.slideUp(200, function () {
      mobileTocContent.remove();
    });
    isTocOpen = false;
  }

  // Smooth scrolling for TOC items
  $(document).on("click", "a[data-section-id]", function (event) {
    event.preventDefault();

    const targetId = $(this).data("section-id");
    const targetElement = contentContainer.find(`[data-section-id="${targetId}"]`);

    if (targetElement.length) {
      $("html, body").animate(
        {
          scrollTop: targetElement.offset().top - headerOffset - 10,
        },
        500
      );

      // Close mobile TOC after clicking an item
      if (!isDesktop() && isTocOpen) {
        closeMobileToc($("#mobile-toc-content"));
      }
    }
  });

  // Sticky behavior for toggle button
  $(window).on("scroll", function () {
    const scrollTop = $(window).scrollTop();
    const isSticky = scrollTop > tocStaticPosition.top;
    const arrowIcon = $('body').find(".fa-chevron-down");


    if (isSticky) {
      tocToggle.addClass("tw-sticky ");
      arrowIcon.removeClass("tw-rotate-180");


    } else {
      tocToggle.removeClass("tw-sticky ");
      arrowIcon.addClass("tw-rotate-180");

    }

    // Highlighting logic for TOC items
    highlightActiveSection();
  });

  function highlightActiveSection() {
    const scrollTop = $(window).scrollTop() + headerOffset + 15;
    let activeId = null;
    let closestSection = null;
    let closestDistance = Number.MAX_SAFE_INTEGER;

    // Determine the closest section upwards based on scroll position
    contentContainer.find("[data-section-id]").each(function () {
        const section = $(this);
        const sectionTop = section.offset().top;
        const distance = scrollTop - sectionTop;

        // Check if the section is above or near the current scroll position
        if (distance >= 0 && distance < closestDistance) {
            closestDistance = distance;
            closestSection = section;
        }
    });

    // Set the active ID to the closest section
    if (closestSection) {
        activeId = closestSection.data("section-id");
    }

    // Update TOC item styles for mobile and desktop separately
    const mobileTocItems = $("#mobile-toc-content a[data-section-id]");
    const desktopTocItems = $("#toc-desktop a[data-section-id]");

    // Reset all TOC items
    mobileTocItems.removeClass("tw-text-dh_primary").addClass("tw-text-gray-800");
    desktopTocItems.removeClass("tw-text-dh_primary").addClass("tw-text-gray-800");

    // Highlight the active item
    if (activeId) {
        if (isTocOpen) {
            // Highlight in mobile TOC
            mobileTocItems
                .filter(`[data-section-id="${activeId}"]`)
                .addClass("tw-text-dh_primary")
                .removeClass("tw-text-gray-800");
        } else if (isDesktop()) {
            // Highlight in desktop TOC
            desktopTocItems
                .filter(`[data-section-id="${activeId}"]`)
                .addClass("tw-text-dh_primary")
                .removeClass("tw-text-gray-800");
        }
    }
}
}